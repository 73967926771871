import Img from "gatsby-image"
import * as queryString from "query-string"
import React from "react"
import { Button, Carousel, Col, Container, Jumbotron, Nav, Navbar, Row } from 'react-bootstrap'
import { FaFacebookF, FaInstagram, FaStar } from 'react-icons/fa'
import '../assets/css/styles.css'
// import Image from "../components/image"
// import SEO from "../components/seo"
import Faq from "../components/faq"
import Layout from "../components/layout"


const IndexPage = ({ data, location }) => {

  // query-string parses the parameters that are contained in the location object;
  const { utm_source, utm_medium, utm_term, utm_content, utm_campaign, fbclid, gclid } = queryString.parse(location.search);

  const param_dict = {
    "utm_source": utm_source,
    "utm_medium": utm_medium,
    "utm_term": utm_term,
    "utm_content": utm_content,
    "utm_campaign": utm_campaign,
    "fbclid": fbclid,
    "gclid": gclid
  }

  console.log(param_dict)

  var param_string = ''

  for (const [key, value] of Object.entries(param_dict)) {
    if (value !== undefined) {
      param_string += `&${key}=${value}`
    }
  }

  return (
    <Layout>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#main">
          <strong>GimbOWL</strong>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" s />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#faq">FAQ</Nav.Link>
            <Nav.Link href="#reviews">Reviews</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container fluid>
        <Row className="banner">
          <Col>
            <div><b>📆 NEW YEAR SALE 49% OFF 📆</b></div>
          </Col>
        </Row>
      </Container>



      <Container id="main" className="mx-auto my-5">
        <Row>
          <Col xs={12} md={6}>
            <Carousel>
              <Carousel.Item>
                <Img fluid={data.carouselImage1.childImageSharp.fluid} alt="first image" />
              </Carousel.Item>
              <Carousel.Item>
                <Img fluid={data.carouselImage2.childImageSharp.fluid} />
              </Carousel.Item>
              <Carousel.Item>
                <Img fluid={data.carouselImage3.childImageSharp.fluid} />
              </Carousel.Item>
              <Carousel.Item>
                <Img fluid={data.carouselImage4.childImageSharp.fluid} />
              </Carousel.Item>
              <Carousel.Item>
                <Img fluid={data.carouselImage5.childImageSharp.fluid} />
              </Carousel.Item>
              <Carousel.Item>
                <Img fluid={data.carouselImage6.childImageSharp.fluid} />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col>
                {/* <h4 className="quotePrimary">"I feel like I'm in a spa" - Cynthia</h4> */}
                <h2><strong>GimbOWL Pro: Your AI Cameraman</strong></h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="rating">
                  <span className="fa fa-star"><FaStar /></span>
                  <span className="fa fa-star"><FaStar /></span>
                  <span className="fa fa-star"><FaStar /></span>
                  <span className="fa fa-star"><FaStar /></span>
                  <span className="fa fa-star"><FaStar /></span>
                  <span className="ratingText">5.0</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="pricing">
                  <p><s>$128.99</s></p>
                  <p><span className="currentPrice">$64.99</span></p>
                  <p>
                    <span className="discountAmount">$64.00 off</span>
                    <span className="discountPercentage"> (-49%)</span>
                  </p>
                </div>
              </Col>
            </Row>
            <Col>
              <Row>
                <Col className="centerText">
                  <a href={`https://gimbowl.myshopify.com/cart/33804907511905:1?channel=buy_button${param_string}`}>
                    <Button variant="primary" className="blackButton" size="lg" block>Buy Black</Button>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className="centerText">
                  <a href={`https://gimbowl.myshopify.com/cart/33660059320417:1?channel=buy_button${param_string}`}>
                    <Button variant="primary" className="whiteButton" size="lg" block>Buy White</Button>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className="centerText">
                  <a href={`https://gimbowl.myshopify.com/cart/33895238041697:1?channel=buy_button${param_string}`}>
                    <Button variant="primary" className="pinkButton" size="lg" block>Buy Pink</Button>
                  </a>
                </Col>
              </Row>
            </Col>
            <Row>
              <p className="paddingTop20">
                <Col className="centerText">
                  <span>100% No-Risk Money Back Guarantee</span>
                </Col>
              </p>
            </Row>
            <Row>
              <Col>
                <div className="leftAlign">
                  <Img className="ccLogo" fluid={data.ccLogo.childImageSharp.fluid} alt="credit card acceptance" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="productDescription">
                <p className="productDescHighlight">
                  Turn your Phone into a Cameraman!
                </p>
                <p className="productDescription">
                  Portable all-in-one auto smart gimbOWL Pro is a next generation gimbal featuring a built-in ultra-wide angle camera and AI algorithm driven by dual-core CPU dedicated to track your movement and keep you in the frame. You can truly go hands free without any app restrictions.
                </p>
                <p className="productDescription">
                  GimbOWL Pro is perfect for video calls, live streaming, tutorials, performance videos, and many more!
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Faq />

      <iframe src="https://loox.io/widget/V1b5BptEdu/reviews/4855243145313?h=1634980060931" width="100%" height="2339"></iframe>

      <Col className="marginTop10">
        <Row>
          <Col className="centerText">
            <a href={`https://gimbowl.myshopify.com/cart/33804907511905:1?channel=buy_button${param_string}`}>
              <Button variant="primary" className="blackButton" size="lg" block>Buy Black</Button>
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="centerText">
            <a href={`https://gimbowl.myshopify.com/cart/33660059320417:1?channel=buy_button${param_string}`}>
              <Button variant="primary" className="whiteButton" size="lg" block>Buy White</Button>
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="centerText">
            <a href={`https://gimbowl.myshopify.com/cart/33895238041697:1?channel=buy_button${param_string}`}>
              <Button variant="primary" className="pinkButton" size="lg" block>Buy Pink</Button>
            </a>
          </Col>
        </Row>
      </Col>

      <Jumbotron className="lightCream centerText footerJumble">
        <Container className="footer">
          <Row className="footer-block">
            <Col className="footerImage">
              <Img fluid={data.footerLogo.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row className="footer-block">
            <Col>
              <p className="footer-description">
                The top-selling automated cameraman anywhere on the internet.
              </p>
            </Col>
          </Row>
          <Row className="footer-block">
            <Col>
              <div className="socialMediaLinks">
                <a className="fa fa-facebook" href="https://facebook.com/gimbowl" target="_blank" rel="noopener noreferrer"><FaFacebookF /><span>fb</span></a>
                <a className="fa fa-instagram" href="https://instagram.com/gimbowl" target="_blank" rel="noopener noreferrer"><FaInstagram /><span>ig</span></a>
              </div>
            </Col>
          </Row>
          <Row className="footer-block">
            <Col>
              <span className="footer-words">
                Designed with <span className="heart">❤</span> in USA & Canada
              </span>
              <span className="footer-words">
                ©2020 Copyright. All rights reserved
              </span>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </Layout>
  );

}

export default IndexPage

export const query = graphql`
  query {
    ccLogo: file(relativePath: { eq: "cc_logos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }    
    carouselImage1: file(relativePath: { eq: "gimbowl-front-white.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage2: file(relativePath: { eq: "gimbowl-front-black.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage3: file(relativePath: { eq: "gimbowl-front-pink.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage4: file(relativePath: { eq: "gimbowl-back-white.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage5: file(relativePath: { eq: "gimbowl-back-black.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage6: file(relativePath: { eq: "gimbowl-back-pink.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerLogo: file(relativePath: { eq: "gimbowl-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

`
