import React from "react"
// import {Link} from "gatsby"
import { Accordion, Card, Col, Container } from 'react-bootstrap'

const Faq = () => (
  <Container id="faq" className="mx-auto my-5">
    <Col className="faqTitle centerText">
      <h1>Frequently Asked Questions</h1>
    </Col>
    <Accordion className="faqDetails" defaultActiveKey="0">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <b>
            Q: Do you have a money-back guarantee? A: Yes, for 30 days.
          </b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>If you are not completely satisfied, return your GimbOWL Pro to us within 30 days and you will receive a refund of either your purchase price or the lowest retail price at which the same item has been offered since your date of purchase. Freight charges are not refundable.</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          <b>
            Q: How does it work? A: Artificial Intelligence.
          </b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body> GimbOWL recognizes and tracks your face or body as you move in front of the gimbOWL Pro. Go fully hands free on video calls and live streaming.</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          <b>
            Q: Do I need an app? A: No!
          </b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>gimbOWL Pro has a built-in camera system, which can track and rotate intelligently. It does not use an app to track so you can freely use all the functions of a mobile phone when shooting.</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          <b>
            Q: What is it's turn radius? A: 360°.
          </b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            GimbOWL has a 360° rotation angle. The lense has a 104° field of view and a 42° rotation per second to ensure that the tracking tripod can follow your movement, wherever you go. You’re the focus, all the time.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4">
          <b>
            Q: Is the battery rechargeable? A: Yes.
          </b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>Built-in 2200 mAh rechargeable lithium battery, can work continuously for 6-8 hours after fully charged.</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="5">
          <b>
            Q: Is it compatible with a tripod? A: Yes.
          </b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="5">
          <Card.Body>GimbOWL Pro comes with a standard 1/4" screw thread at the bottom that enables you to mount it to your tripod and adjust its angle and height to easily capture your desired results.</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="6">
          <b>
            Q: How long does shipping take? A: As little as 3 days (pending location).
          </b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="6">
          <Card.Body>In the US: 3-5 days. Canada: 3-10 days. Other Countries: 10-20 days.</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </Container>
)

export default Faq
